import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import BottomTabs from './BottomTab';
import { PATH_DASHBOARD } from '../routes/paths';
// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  width: '100%',
  maxWidth: `760px`,
  margin: '0 auto',
  alignSelf: 'center',
  height: '100%',
  background: theme.palette.background.default,
  minHeight: '100vh',
}));

// ----------------------------------------------------------------------

const initialRoutes = [
  PATH_DASHBOARD.home,
  PATH_DASHBOARD.player.root,
  PATH_DASHBOARD.wallet.root,
  PATH_DASHBOARD.transaction.root,
  PATH_DASHBOARD.account.root,
];
export default function DashboardLayout() {
  const [showBottom, setShowBottom] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setShowBottom(initialRoutes.includes(pathname));

    return () => {};
  }, [pathname]);

  return (
    <MainStyle>
      <Outlet />
      {showBottom && <BottomTabs />}
    </MainStyle>
  );
}
