/* eslint-disable react/prop-types */
import { m } from 'framer-motion';
// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
//
import CircularProgress from '@mui/material/CircularProgress';
import Logo from './Logo';
import BeatLoader from 'react-spinners/BeatLoader';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  minHeight: `100vh`,
}));

// ----------------------------------------------------------------------
export default function LoadingScreen({ mainLoading = false, ...other }) {
  const theme = useTheme();
  return (
    <RootStyle {...other}>
      <BeatLoader
        color={theme.palette.primary.main}
        loading={true}
        cssOverride={{}}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </RootStyle>
  );
}
