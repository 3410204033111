import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Layout from '../layout';

// guards
// eslint-disable-next-line import/no-named-as-default, import/no-named-as-default-member
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/common/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/authentication/ForgotPassword')));
// const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// const NewPassword = Loadable(lazy(() => import('../pages/authentication/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/Verify')));

// DASHBOARD

// GENERAL
const Home = Loadable(lazy(() => import('../pages/home')));
const Player = Loadable(lazy(() => import('../pages/player')));
const PlayerDetail = Loadable(lazy(() => import('../pages/player/Detail')));
const PlayerBetDetail = Loadable(lazy(() => import('../pages/player/BetDetail')));
const PlayerTransitionDetail = Loadable(lazy(() => import('../pages/player/TransitionDetail')));

const ChooseAvatar = Loadable(lazy(() => import('../pages/account/ChooseAvatar')));

const Wallet = Loadable(lazy(() => import('../pages/wallet')));
const RequestList = Loadable(lazy(() => import('../pages/wallet/RequestList')));
const RequestHistoryDetail = Loadable(lazy(() => import('../pages/wallet/RequestHistoryDetail')));
const RequestPendingDetail = Loadable(lazy(() => import('../pages/wallet/RequestPendingDetail')));
const CheckoutList = Loadable(lazy(() => import('../pages/wallet/CheckoutList')));
const RequestCheckout = Loadable(lazy(() => import('../pages/wallet/RequestCheckout')));
const CheckoutRequest = Loadable(lazy(() => import('../pages/wallet/CheckoutRequest')));

const CommissionList = Loadable(lazy(() => import('../pages/wallet/CommissionList')));
const Notifications = Loadable(lazy(() => import('../pages/home/Notifications')));

const Transaction = Loadable(lazy(() => import('../pages/transaction')));
const Account = Loadable(lazy(() => import('../pages/account')));
const ChangePassword = Loadable(lazy(() => import('../pages/account/ChangePassword')));
const PaymentMethods = Loadable(lazy(() => import('../pages/account/PaymentMethods')));
const ContactInformation = Loadable(lazy(() => import('../pages/account/ContactInformation')));

const Page500 = Loadable(lazy(() => import('../pages/error/Page500')));
const PageMaintenance = Loadable(lazy(() => import('../pages/error/Maintenance')));
const Page404 = Loadable(lazy(() => import('../pages/error/Page404')));

export default function Router() {
  return useRoutes([
    // Authentication Routes
    {
      path: 'auth',
      element: (
        <GuestGuard>
          <Layout />
        </GuestGuard>
      ),
      children: [
        { path: 'login', element: <Login /> },
        // { path: "register", element: <Register /> },
      ],
    },
    // Public Routes
    {
      path: 'auth',
      element: <Layout />,
      children: [
        { path: 'forgot-password', element: <ForgotPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },
    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <Layout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Home /> },
        { path: 'notifications', element: <Notifications /> },

        {
          path: 'players',
          children: [
            { path: '', element: <Player /> },
            { path: ':pid', element: <PlayerDetail /> },
            { path: 'bet/:pid/:type/:tpid', element: <PlayerBetDetail /> },
            { path: 'transition/:pid/:type/:tid', element: <PlayerTransitionDetail /> },
          ],
        },
        {
          path: 'wallet',
          children: [
            { path: '', element: <Wallet /> },
            { path: 'request-list', element: <RequestList /> },
            { path: 'request-list/history-details/:pid/:type/:tid', element: <RequestHistoryDetail /> },
            { path: 'request-list/pending-details/:pid/:type/:tid', element: <RequestPendingDetail /> },
            { path: 'checkout-list', element: <CheckoutList /> },
            { path: 'checkout-list/request/', element: <RequestCheckout /> },
            { path: 'checkout-list/request/:rid', element: <CheckoutRequest /> },
            { path: 'commission/', element: <CommissionList /> },
          ],
        },
        {
          path: 'transaction',
          children: [{ path: '', element: <Transaction /> }],
        },
        {
          path: 'account',
          children: [
            { path: '', element: <Account /> },
            { path: 'change-password/', element: <ChangePassword /> },
            { path: 'payment-methods/', element: <PaymentMethods /> },
            { path: 'contact-information/', element: <ContactInformation /> },
            { path: 'choose-avatar/', element: <ChooseAvatar /> },
          ],
        },
      ],
    },
    // Errors Routes
    // {
    //   path: '*',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: 'maintenance', element: <PageMaintenance /> },
    //     { path: '500', element: <Page500 /> },
    //     { path: '404', element: <Page404 /> },
    //     { path: '*', element: <Navigate to="/404" replace /> },
    //   ],
    // },
  ]);
}
