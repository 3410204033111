const en = {
  // Tabs
  Home: `Home`,
  Player: `Player`,
  Wallet: `Wallet`,
  Transaction: `Transaction`,
  Account: `Account`,
  // Button
  Create: 'Create',
  Cancel: 'Cancel',
  Accept: 'Accept',
  Reject: 'Reject',
  Next: 'Next',
  Request: 'Request',
  Confirm: 'Confirm',
  Save: 'Save',
  CancelSaving: 'Cancel',
  Send: 'Send',
  Edit: 'Edit',
  // Common
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  Accepted: 'Accepted',
  Rejected: 'Rejected',
  Status: 'Status',
  Bet: 'Bet History',
  Deposit: 'Deposit Histroy',
  Withdraw: 'Withdraw History',
  ThaiLottery: 'Thai Lottery',
  SingaporeSweep: 'Singapore Sweep',
  Name: 'Name',
  WinningAmount: 'Winning Amount',
  Income: 'Income',
  Outgoing: 'Outgoing',
  // Home Page
  FlowWallet: 'Flow Wallet',
  CommissionWallet: 'Commission Wallet',
  TotalPlayer: 'Total Player',
  ActivePlayer: 'Active Player',
  Requests: 'Requests',
  SelectDate: 'Select Date',

  // Plaer Page
  PlayerManagement: 'Player Management',
  CreateNewPlayer: 'Create New Player',
  PlayersName: "Player's Name",
  EnterPlayersName: "Enter Player's Name",
  PhoneNumber: 'Phone No.',
  EnterPhoneNumber: 'Enter Phone No.',
  Password: 'Password',
  EnterPassword: 'Enter Password',
  ConfirmPassword: 'Confirm Password',
  EnterConfirmPassword: 'Enter Confirm Password',
  // Player Detail
  TotalBetAmount: 'Total Bet Amount',
  TotalWinAmount: 'Total Win Amount',
  Phone: 'Phone',
  TicketNumbers: 'Ticket Number',
  Details: 'Details',
  ResetPassword: 'Reset Password',
  BetDetail: 'Bet Detail',

  // Wallet Page
  WalletManagement: 'Wallet Management',
  Checkout: 'Checkout',
  Commission: 'Commission',
  // Request Page
  Pending: 'Pending',
  History: 'History',
  // History Detail
  HistoryDetail: 'History Detail',
  Type: 'Type',
  Date: 'Date',
  Time: 'Time',
  RequestTime: 'Request Time',
  PlayerName: 'Player Name',
  PlayerID: 'Player ID',
  PaymentType: 'Payment Type',
  AccountName: 'Account Name',
  AccountNumber: 'Account Number',
  AgentAccountName: 'Agent Account Name',
  AgentAccountNumber: 'Agent Account Number',
  TransactionID: 'Transaction ID',
  EnterTransactionID: 'Enter Transaction ID',
  Amount: 'Amount',
  EnterRejectNote: 'Enter Reject Note',
  EnterAddNote: 'Enter Add Note',
  AddNote: 'Add Note',
  // Checkout Page
  RequestCheckout: 'Request Checkout',
  CheckoutType: 'Checkout Type',
  FlowAmount: 'Flow Amount',
  EnterFlowAmount: 'Enter Flow Amount',
  SelectAdminPaymentMethod: 'Select Admin Payment Method',
  AdminAccountName: 'Admin Account Name',
  AdminAccountNumber: 'Admin Account Number',
  PaymentStatus: 'Payment Status',
  PaidOn: 'Paid On',
  CheckoutRequest: 'Checkout Request',
  PayLater: 'Pay Later',
  PayNow: 'Pay Now',
  PaymentDate: 'Payment Date',
  PaymentNote: 'Payment Note',
  Note: 'Note',
  // Commission Page
  TotalSale: 'Total Sale',
  // Transaction Page
  // Profile Page
  Profile: 'Profile',
  ProfileDetails: 'Profile Detail',
  ChangePassword: 'Change Password',
  ContactInformation: 'Contact Information',
  PaymentMethod: 'Payment Method',
  PaymentMethods: 'Payment Methods',
  Notification: 'Notification',
  Notifications: 'Notifications',
  AgentCode: 'Agent Code',
  Copy: 'Copy',
  Logout: 'Logout',
  // Change Password Page
  CurrentPassword: 'Current Password',
  EnterCurrentPassword: 'Enter Current Password',
  NewPassword: 'New Password',
  EnterNewPassword: 'Enter New Password',
  ConfirmNewPassword: 'Confirm New Password',
  EnterConfirmPassword: 'Enter Confirm Password',
  // Contact information page
  MyContactInformation: 'My Contact Information',
  AddNewContactInformation: 'Add New Contact Information',
  SelectContactInformation: 'Select Contact Information',
  Number: 'Number',
  EnterNumber: 'Enter Number',
  // Payment Method
  MyPaymentMethod: 'My Payment Method',
  AddNewPaymentMethod: 'Add New Payment Method',
  EditPaymentMethod: 'Edit Payment Method',
  SelectPaymentMethod: 'Select Payment Method',
  EnterAccountName: 'Enter Account Name',
  EnterAccountNumber: 'Enter Account Number',
  RedirectToPayment: 'No Payment Method Found! Add new payment.',
};

export default en;
