// ----------------------------------------------------------------------

function path(root, subLink) {
  return `${root}${subLink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  home: ROOTS_DASHBOARD,
  player: {
    root: path(ROOTS_DASHBOARD, 'players'),
  },
  wallet: {
    root: path(ROOTS_DASHBOARD, 'wallet'),
  },
  transaction: {
    root: path(ROOTS_DASHBOARD, 'transaction'),
  },
  account: {
    root: path(ROOTS_DASHBOARD, 'account'),
  },
};
